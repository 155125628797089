import { CurrencyType } from "./global.store";
import { MonthType } from "types/MonthType";
import { PeriodType } from "types/PeriodType";
import { Properties } from "helpers/properties";
import { QuarterType } from "types/QuarterType";
import { ShowByType } from "types/ShowByType";
import { GetLastUpdated } from "pages/app.service";
import moment from "moment";

const dateStr = GetLastUpdated().then((result) => {
	return Promise.resolve(result);
});

function SetupInitialState() {
	const filtersState: FilterStateType = {
		showBy: "mmbtu",
		currency: {
			name: Properties.get("defaultCurrency"),
			currencySymbol: Properties.getCurrencySymbol("USD")
		},
		period: "monthly",
		quarter: Properties.getCurrentQuarter(new Date().getMonth()).toString(),
		month: Properties.getCurrentMonth(new Date().getMonth()).toString(),
		year: "",
		ccngMonth: Properties.getCurrentMonth(new Date().getMonth()).toString(),
		ccngYear: "",
		lngMonth: Properties.getCurrentMonth(new Date().getMonth()).toString(),
		lngYear: "",
		srmcMonth: Properties.getCurrentMonth(new Date().getMonth()).toString(),
		srmcYear: "",
	}

	dateStr.then(result => {

		let updateDate: moment.MomentInput, updateDateCcng: moment.MomentInput, updateDateLng: moment.MomentInput, updateDateSrmc: moment.MomentInput;
		let formattedDate: Date, formattedDateCcng: Date, formattedDateLng: Date, formattedDateSrmc: Date;

		if (window.location.href.includes("lng")) {

			if (window.location.href.includes("srmc")) {
				updateDate = result.srmcDataAsOfLastUpdate;
				formattedDate = moment(updateDate).toDate();
				filtersState.srmcMonth = formattedDate.toLocaleString("default", { month: "long" }) as MonthType;
				filtersState.srmcYear = formattedDate.getFullYear().toString();

				updateDateLng = result.lngLastUpdate;
				formattedDateLng = moment(updateDateLng).toDate();
				filtersState.lngMonth = formattedDateLng.toLocaleString("default", { month: "long" }) as MonthType;
				filtersState.lngYear = formattedDateLng.getFullYear().toString();

			} else {
				updateDate = result.lngLastUpdate;
				formattedDate = moment(updateDate).toDate();
				filtersState.lngMonth = formattedDate.toLocaleString("default", { month: "long" }) as MonthType;
				filtersState.lngYear = formattedDate.getFullYear().toString();

				updateDateSrmc = result.srmcDataAsOfLastUpdate;
				formattedDateSrmc = moment(updateDateSrmc).toDate();
				filtersState.srmcMonth = formattedDateSrmc.toLocaleString("default", { month: "long" }) as MonthType;
				filtersState.srmcYear = formattedDateSrmc.getFullYear().toString();

			}
			filtersState.month = formattedDate.toLocaleString("default", { month: "long" }) as MonthType;
			filtersState.year = formattedDate.getFullYear().toString();

			updateDateCcng = result.ccngLastUpdate;
			formattedDateCcng = moment(updateDateCcng).toDate();
			filtersState.ccngMonth = formattedDateCcng.toLocaleString("default", { month: "long" }) as MonthType;
			filtersState.ccngYear = formattedDateCcng.getFullYear().toString();

		} else {
			updateDate = result.ccngLastUpdate;
			formattedDate = moment(updateDate).toDate();
			filtersState.month = formattedDate.toLocaleString("default", { month: "long" }) as MonthType;
			filtersState.year = formattedDate.getFullYear().toString();

			filtersState.ccngMonth = formattedDate.toLocaleString("default", { month: "long" }) as MonthType;
			filtersState.ccngYear = formattedDate.getFullYear().toString();

			updateDateLng = result.lngLastUpdate;
			formattedDateLng = moment(updateDateLng).toDate();
			filtersState.lngMonth = formattedDateLng.toLocaleString("default", { month: "long" }) as MonthType;
			filtersState.lngYear = formattedDateLng.getFullYear().toString();

			updateDateSrmc = result.srmcDataAsOfLastUpdate;
			formattedDateSrmc = moment(updateDateSrmc).toDate();
			filtersState.srmcMonth = formattedDateSrmc.toLocaleString("default", { month: "long" }) as MonthType;
			filtersState.srmcYear = formattedDateSrmc.getFullYear().toString();
		}
	});
	return filtersState;
}

export const initialFiltersState: FilterStateType = SetupInitialState();

export type FilterStateType = {
	showBy: ShowByType | string;
	currency: CurrencyType;
	period: PeriodType;
	quarter: QuarterType;
	month: MonthType;
	year: string;
	ccngMonth: MonthType;
	ccngYear: string;
	lngMonth: MonthType;
	lngYear: string;
	srmcMonth: MonthType;
	srmcYear: string;
};
