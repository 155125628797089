import React, { useEffect, useReducer } from "react"
import { AppStore } from "store"
import { AppStoreProps } from "store/app/app.store";
import { isNullOrUndefined } from "util";
import { Properties } from "helpers/properties";
import { HeaderSubtitle, StickyElement, Typography, BuildupChart, LegendContainer, BoxAndLabel, PopupBox } from "components/molecules";
import { LNGStore, LNGStoreProps } from "store/lng/lng.store";
import { SRMCFilters } from "./components/SRMCFilters";
import SRMCTable from "./components/SRMCTable/SRMCTable";
import { TabTable } from "components/organisms/TabTable/TabTable";
import { SquareTab } from "components/organisms/TabTable/SquareTab/SquareTab";
import { LoadProjection, LoadYears } from "./SRMC.service";
import { ShowByType } from "types/ShowByType";
import { MonthType } from "types/MonthType";
import { PeriodType } from "types/PeriodType";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { CostMarginResult } from "../CostMargin/type/CostMarginResult";
import { ProjectionData, SRMCSegments } from "types/ProjectionData";
import { Skeleton } from "helpers/hooks/Loader";
import { LNGUtil, Util } from "helpers/util/util";
import * as d3 from "d3";
import "./SRMC.scss";

type Props = AppStoreProps & LNGStoreProps;

type State = {
	showBy: ShowByType | string;
	viewType: PeriodType;
	month: MonthType;
	year: string;
	valueChain: string;
	EntLng: string;
	projectionData: ProjectionData;
	loading: boolean;
	yearList: Array<DropdownItemType>;
};

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_FILTER":
			if (isNullOrUndefined(action["key"])) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}
			if (isNullOrUndefined(action["data"])) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}
			return { ...state, [action.key]: action.data };
		case "UPDATE_LOADING":
			return { ...state, loading: action.data };
		case "UPDATE_PROJECTION":
			return { ...state, projectionData: action.data };
		case "UPDATE_CHART_DATA":
			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_CHART_DATA' to work");
			}
			return {
				...state, data: action.data as CostMarginResult
			};
		default:
			throw new Error();
	}
}

/*
	FUNCTION NAME: SRMC
	DESCRIPTION: The function that renders the SRMC screen
*/
let buildUpChart;
let errorMsg = "";
function SRMC({ lng, dictionary, global, filters }: Props) {
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;
	const currency = lng.get("currency");
	const currencySymbol = lng.get("currency").currencySymbol;
	const currentMonth = filters.get("month");
	const currentYear = filters.get("year");

	const [state, dispatch] = useReducer(reducer, {
		showBy: "mmbtu",
		viewType: "monthly",
		month: currentMonth,
		year: currentYear,
		valueChain: "malaysia-mlng1",
		EntLng: "enterprise",
		projectionData: {},
		loading: true,
		yearList: []
	});

	const toggleLoadingChange = (value: boolean) => dispatch({ type: "UPDATE_LOADING", data: value });
	const handleFilterChange = (key: string, value: any) => {
		dispatch({ type: "UPDATE_FILTER", key: key, data: value.id || value });
	};
	const segments: SRMCSegments[] = state.projectionData?.segments || [];

	const _loadChart = (
		chartData: any,
		create: boolean,
		currentSegment: string,
		subsegment: string,
		hideMargin: boolean,
		updateViewOnly?: boolean
	) => {
		const hideSegmentMargin = currentSegment === "all" && subsegment === "all";

		if (!updateViewOnly) {
			if (create) {
				buildUpChart = new window["BuildUpChart"](
					".build-up-chart",
					chartData,
					"segments",
					450,
					currencySymbol,
					hideMargin,
					hideSegmentMargin,
					"SRMC"
				);
				if (buildUpChart.data) {
					buildUpChart.init();
				}
			} else {
				buildUpChart.update(
					chartData,
					currencySymbol,
					hideMargin,
					hideSegmentMargin,
					state.showBy
				);
			}
		} else {
			buildUpChart.update(
				chartData,
				currencySymbol,
				hideMargin,
				hideSegmentMargin,
				state.showBy
			);
		}
	};
	const getAPIParams = () => {
		return {
			showBy: state.showBy,
			currency: currency.name,
			year: state.year,
			period: state.month,
			periodType: state.viewType,
			valueChain: state.valueChain,
			enterpriseLng: state.EntLng,
		};
	};

	useEffect(() => {
		_loadChart(null, true, "all", "all", false, false);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		state.year = currentYear;
		state.month = currentMonth;
	}, [currentYear, currentMonth]);

	useEffect(() => {
		const params = getAPIParams();
		toggleLoadingChange(true);
		LoadProjection(params).then((result) => {
			dispatch({ type: "UPDATE_PROJECTION", data: result });
			if (result.segments.length > 0) {
				errorMsg = "";
			}
			else {
				errorMsg = Properties.getDictionaryText(dictionary.get("dictionary"), `SRMC_Table_Error_${state.viewType}`, "en");
			}
			toggleLoadingChange(false);
			_loadChart(result, false, "all", "all", false, true);
		});
		// eslint-disable-next-line
	}, [state.showBy, state.viewType, state.month, state.year, state.valueChain, state.EntLng]);

	useEffect(() => {
		const errorMessageBlock = d3.select("#no_data_svg_text");
		errorMsg && errorMessageBlock.text("");
	});

	React.useEffect(() => {
		LoadYears().then((data) => {
			const current = data[0];
			const next = current + 1;
			data.unshift(next);
			handleFilterChange("yearList", { id: LNGUtil.loadYears(data) });
		});
		// eslint-disable-next-line
	}, []);

	const jkmLgendLabel = () => {
		if (state.projectionData.jkmDate) {
			const jkmDate = new Date(state.projectionData.jkmDate);
			let month = `${jkmDate.getMonth() + 1}`;
			let day = `${jkmDate.getDate()}`;
			const year = jkmDate.getFullYear();
			if (month.length < 2)
				month = `0${month}`;
			if (day.length < 2)
				day = `0${day}`;

			return `JKM As Date ${day}-${month}-${year}`;
		}
		return "JKM";
	}
	const isActualMonthly = state.viewType === "monthly" ? state.projectionData.actual ? "_actual" : "_projected" : "";
	const chartHeading = Properties.getDictionaryText(dictionary.get("dictionary"), `SRMC_chart_label_${state.viewType}${isActualMonthly}`, "en");

	const chartSubHeading = () => {
		if (state.viewType === "monthly") {
			return state.projectionData.actual ? `Actuals up to ${Util.getFormattedLastUpdated(global.get("lastUpdatedDate").srmcActualLastUpdate)}` : `Projections up to ${Util.getFormattedLastUpdated(global.get("lastUpdatedDate").srmcProjectedLastUpdate)}`;
		} else if (state.viewType === "ytd") {
			return `Actuals up to ${Util.getFormattedLastUpdated(global.get("lastUpdatedDate").srmcActualLastUpdate)}`;
		}
		else {
			return `Actuals up to ${Util.getFormattedLastUpdated(global.get("lastUpdatedDate").srmcActualLastUpdate)} And Projections up to ${Util.getFormattedLastUpdated(global.get("lastUpdatedDate").srmcProjectedLastUpdate)}`;
		}
	};

	return (
		<>
			<section className="cost-margin">
				<HeaderSubtitle heading="Short Run Marginal Cost (SRMC)" subtitle={Properties.getDictionaryText(dictionary.get("dictionary"), `LNG_SRMC_subheader`, "en")} className="SRMC-header-with-subtitles" />
				<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="SRMC-filters" stickyClassName="sticky">
					<SRMCFilters
						showBy={state.showBy}
						viewType={state.viewType}
						month={state.month}
						year={state.year}
						yearList={state.yearList}
						valueChain={state.valueChain}
						EntLng={state.EntLng}
						currency={currency}
						hideQuarter
						stickyClassName="SRMC-filters" //This stickyClassName is meant for the JS for scrolling
						onEvent={handleFilterChange} />
				</StickyElement>
				<div className="SRMC-header-with-subtitles_chart">
					<Typography.Heading3 className={"heading"} weight={500} color={"Grey"}>{chartHeading}</Typography.Heading3>
					<Typography.SmallText color={"Silver"} weight={500}>{chartSubHeading()}</Typography.SmallText>
				</div>
				<div className="cost-margin__filter-chart">
					{/* Need this new class to make it flex grow */}
					<BuildupChart errorMessage={errorMsg} loading={state.loading} hasData={(state.projectionData && state.projectionData.segments?.length > 0) || false} className="cost-margin-buildup-chart-parent">
						<LegendContainer className="buildup-chart-legends SRMC-legends">
							<LegendContainer className="unit-margin__legends">
								<BoxAndLabel label="SRMC" boxColor="teal" box="box" />
								{state.showBy !== "absolute" &&
									<>
										<BoxAndLabel label={!errorMsg ? jkmLgendLabel() : "JKM"} boxColor="twilight" box="box" />
										<BoxAndLabel label="Margin" boxColor="sand" box="box" />
									</>
								}
							</LegendContainer>
						</LegendContainer>
						<div className="SRMC_chart_note">
							<Typography.SmallText color={"Grey"}>&#42; UMC - Unit Manufacturing Cost  &#42; VSRC - Variable Shipping Related Cost</Typography.SmallText>
						</div>
						<PopupBox id="buildupchart_Selected-details" className="--hidden" />
					</BuildupChart>
				</div>
				<Skeleton loading={state.loading.details}>
					{segments.length > 0 && <TabTable defaultPage={0}>
						{segments.map((val, index) => (
							<SquareTab label={val.segment} id={index} key={index}>
								<Typography.Heading1>Details</Typography.Heading1>
								{state.EntLng === "enterprise" && val.segment === "Feedgas Cost" &&
									<SRMCTable currencySymbol={currency.currencySymbol} subSegments={val.details.psc} headerLabel={"psc/rsc"} />
								}
								<SRMCTable currencySymbol={currency.currencySymbol} subSegments={val.details.plant} headerLabel={"plant"} />
							</SquareTab>
						))}
					</TabTable>}
				</Skeleton>
			</section>
		</>
	);
}

export default AppStore.withStores(LNGStore.withStores(SRMC));