import React from "react"
import { AppStore } from "store";
import { AppStoreProps } from "store/app/app.store";
import { ComparativeType } from "types/ComparativeType";
import { LNGFilters } from "./components/LNGFilters";
import { Properties } from "helpers/properties";
import { ValueChainSummary } from "./types/ValueChainSummary";
import { ValueChainType } from "types/ValueChainType";
import { HeaderInfoPopup, HeaderSubtitle, StickyElement } from "components/molecules";
import { LNGStore, LNGStoreProps } from "store/lng/lng.store";
import { SubSegment, ValueChainDetailResult } from "./types/ValueChainDetailResult";
import { getLNGValueChainsSubSegmentDetails, getLNGValueChainsSummary, getValueChainDetails, LoadYears } from "./Overview.service";
import { DropdownItemType } from "components/organisms/Dropdown/Dropdown";
import { WithChild } from "components/molecules/TableAccordion/TableAccordion";
import LNGContributors from "./components/LNGContributors";
import LNGPriceBenchmark from "./components/LNGPriceBenchmark";
import LNGSegments from "./components/LNGSegments";
import LNGSubSegments from "./components/LNGSubSegments";
import { LNGUtil } from "helpers/util/util";
import { Skeleton } from "helpers/hooks/Loader";
import "./Overview.scss";

type Props = AppStoreProps & LNGStoreProps;

type State = {
	selectedValueChain: ValueChainType;
	summary?: ValueChainSummary;
	details?: {
		upstream?: ValueChainDetailResult,
		asset?: ValueChainDetailResult,
		lmt?: ValueChainDetailResult,
		integrated?: ValueChainDetailResult,
	};
	loading: {
		summary: boolean,
		details: boolean,
	};
	product: string;
	yearList: Array<DropdownItemType>;
}

/**
 * This should always return new state.
 * **/
function reducer(state: State, action: { type: string, data?: any, key?: string, [key: string]: any }) {
	switch (action.type) {
		case "UPDATE_SUMMARY":
			return {
				...state, summary: (action.data)
			};
		case "UPDATE_DETAILS":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_DETAILS' to work");
			}

			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_DETAILS' to work");
			}

			return { ...state, details: { ...state.details, [action.key]: action.data } };
		case "UPDATE_FILTER":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_FILTER' to work");
			}

			if (!action["data"]) {
				throw new Error("You must pass 'data' in action for 'UPDATE_FILTER' to work");
			}

			return { ...state, [action.key]: action.data };

		case "SELECT_VALUECHAIN":
			return { ...state, selectedValueChain: action.data };

		case "UPDATE_LOADING":
			if (!action["key"]) {
				throw new Error("You must pass 'key' in action for 'UPDATE_LOADING' to work");
			}

			if (action.data === undefined) {
				throw new Error("You must pass 'data' in action for 'UPDATE_LOADING' to work");
			}
			return { ...state, loading: { ...state.loading, [action.key]: action.data } };

		case "UPDATE_SUBSEGMENT_DETAILS":
			return { ...state, subSegmentsDetail: action.data };

		case "UPDATE_SUBSEGMENT_EXPANDED_ROW":
			return { ...state, expandedChildId: action.data };

		default:
			throw new Error();
	}
}
/**
	* This is part of React Hooks feature by React Team; Helps reduce code lines and improve DX.
	* @see https://reactjs.org/docs/hooks-intro.html for react hooks introduction.
	*
	* I am passing reducer function in the first parameter and the second parameter is the object of default/initial state.
	* Check out the reducer(...) function for implementation of state overrides.
	* (Official docs) @see https://reactjs.org/docs/hooks-reference.html#usereducer
	* (Video tutorial) @see https://www.youtube.com/watch?v=wcRawY6aJaw
	*
	* use the 'state' to get the current state. It's similar to 'this.state' from class component
	* while 'dispatch' is similar to 'this.setState' from class component.
	* look below for implementation usage for more information.
 **/
/*
	FUNCTION NAME: Overview
	DESCRIPTION: The function that renders the LNG Overview screen
*/
function Overview({ filters, dictionary, lng }: Props) {
	const [state, dispatch] = React.useReducer(reducer, {
		selectedValueChain: "upstream",
		product: "all",
		loading: {
			summary: true,
			details: true,
		},
		yearList: [],
	});

	const showBy = filters.get("showBy");
	const currency = lng.get("currency");
	const dictionaryList = dictionary.get("dictionary");
	// CustomLogic: Custom logic here.
	const comparative = lng.get("comparative") === "yoy" ? "actual_preceding" : lng.get("comparative");
	const profitability = lng.get("profitability");
	const valueChain = lng.get("valueChain");
	const period = filters.get("period");
	const month = filters.get("month");
	const quarter = filters.get("quarter");
	const year = filters.get("year");

	const lngMonth = filters.get("lngMonth");
	const lngYear = filters.get("lngYear");

	const segments = state.summary && state.summary.segments;
	const currentValueChainDetails = state.details && state.details[state.selectedValueChain];
	const contributions = currentValueChainDetails && currentValueChainDetails.contributors;
	const subSegments = currentValueChainDetails && currentValueChainDetails.subSegments;
	const priceBenchmark = state.summary && state.summary.priceBenchMark;
	const ESTIMATED_APP_HEADER_HEIGHT = 2.9125;

	let comparative_param: ComparativeType = comparative;
	let typeList = [
		{
			label: "Monthly",
			id: "monthly"
		},
		{
			label: "Quarterly",
			id: "quarterly"
		},
		{
			label: "YTD",
			id: "ytd"
		}
	];

	if (comparative === "actual_corresponding") {
		comparative_param = "yoy";
	} else if (comparative === "actual_preceding") {
		typeList = [
			{
				label: "Monthly",
				id: "monthly"
			},
			{
				label: "Quarterly",
				id: "quarterly"
			}
		];
	}

	const handleFilterChange = (key: string, value: any) => {
		switch (key) {
			case "comparative":
				// Do this special logic provided by clients before you update local state.
				if (value.id === "actual_preceding" && period === "ytd") {
					filters.set("period")("monthly");
				}
			// eslint-disable-next-line
			case "profitability":
			case "valueChain":
				lng.set(key)(value.id);
				break;
			case "showBy":
			case "month":
			case "quarter":
			case "period":
			case "year":
				// this will trigger re-render too but the values are global to component.
				filters.set(key)(value.id);
				break;
			default:
				// this will trigger re-render too but the values are local to component.
				dispatch({ type: "UPDATE_FILTER", key: key, data: value.id });
		}
	};
	const handleTabChange = (key: string) => dispatch({ type: "SELECT_VALUECHAIN", data: key });
	const handleDetailsChange = (details: ValueChainDetailResult) => dispatch({ type: "UPDATE_DETAILS", data: details, key: state.selectedValueChain })
	const handleSummaryChange = (summary: ValueChainSummary) => dispatch({ type: "UPDATE_SUMMARY", data: summary })
	const handleLoading = (key: string, loading: boolean = true) => dispatch({ type: "UPDATE_LOADING", data: loading, key: key })
	const getAPIParams = () => {
		const isQuarterly = period === "quarterly";
		const isMonthly = period === "monthly";

		return {
			currency: currency.name,
			comparative: comparative_param,
			periodType: period,
			period: (isQuarterly && quarter) || (isMonthly && month) || `ytd-${month}`,
			year: year,
			valueChain: valueChain,
			showBy: showBy,
			profitability: profitability,
		};
	}


	const _getSubsegmentName = (key) => {
		return Properties.getDictionary(key, `${state.selectedValueChain}_tab`);
	}
	const _getSubsegmentUnit = (key) => {
		const footerLabel = Array<string>();

		const unit = Properties.getUnitForSubSegmentDetails(key, showBy, state.selectedValueChain);
		unit && footerLabel.push(
			String(`(${Properties.getUnitForSubSegmentDetails(key, showBy, state.selectedValueChain)})`)
		);
		return footerLabel;
	}
	const getCurrency = (key) => {
		return Properties.showCurrency(key)
			? Properties.getCurrencySymbol(filters.get("currency").currencySymbol)
			: ""
	}

	const onRowClicked = (item: WithChild<SubSegment>) => {
		if (item)
			getLNGValueChainsSubSegmentDetails(state.selectedValueChain, item.name, getAPIParams()).then((data) => {
				const subSegmentsDetailList: any[] = [];
				Object.keys(data.subSegmentDetails).forEach((key, ind) => {
					if (((lng.get("valueChain")).includes("all")) || key !== "productionVolume") {
						subSegmentsDetailList.push({
							...data.subSegmentDetails[key],
							opposite: Properties.showOppositeTrend(key),
							name: `${_getSubsegmentName(key)} ${_getSubsegmentUnit(key)}`,
							currency: getCurrency(key)
						});
					}
				});
				const currentValueChainData: ValueChainDetailResult = state.details?.[state.selectedValueChain];
				const selectedRowIndex = currentValueChainData.subSegments.findIndex((v) => v.name === item.name);
				currentValueChainData.subSegments[selectedRowIndex]["children"] = subSegmentsDetailList;
				handleDetailsChange({ ...currentValueChainData });
			})
	}

	/**
	 * This is part of React Hooks feature by React Team; Helps reduce code lines and improve DX.
	 * @see https://reactjs.org/docs/hooks-intro.html for react hooks introduction
	 *
	 * 'Use Effect' is similar to componentDidMount() {...} in a class component.
	 * since this component is a functional component we can't use 'componentDidMount' hence we use 'useEffect'
	 *
	 * You can learn more about 'useEffect' at
	 * (Official docs) @see https://reactjs.org/docs/hooks-effect.html
	 * (Video tutorial) @see https://www.youtube.com/watch?v=K4xfCIRuf54
	 *
	 * This 'useEffect' hook will only run when 'showBy, currency, comparative, month, quarter, period, year' changes. Check out second parameter of this hook/function.
	 **/
	React.useEffect(() => {
		handleLoading("summary", true);
		handleLoading("details", true);
		const params = getAPIParams();

		// Call initial API.
		// You need this(Promise.all) to run both API's parallel and async.
		/** @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all **/

		if (lngMonth === month && lngYear === year) {
			Promise.all([
				// 1. Get Summary
				getLNGValueChainsSummary(params),
				// 2. Get value chain details
				getValueChainDetails(state.selectedValueChain, params)
			])
				.then((result) => {
					return {
						summary: result[0],
						details: result[1]
					}
				})
				.then((values) => {
					handleSummaryChange(values.summary)
					return values;
				})
				.then((values) => handleDetailsChange(values.details))
				.then(() => {
					handleLoading("summary", false);
					handleLoading("details", false);
				});
		}
		// eslint-disable-next-line
	}, [showBy, currency, comparative, month, quarter, period, year, profitability, valueChain,])

	React.useEffect(() => {
		LoadYears().then((data) => {
			handleFilterChange("yearList", { id: LNGUtil.loadYears(data) });
		});
		// eslint-disable-next-line
	}, [])

	React.useEffect(() => {
		if (!state.summary && !state.details) {
			return;
		}

		handleLoading("details", true);

		const params = getAPIParams();
		getValueChainDetails(state.selectedValueChain, params)
			.then(handleDetailsChange)
			.then(() => {
				handleLoading("details", false);
			});
		// eslint-disable-next-line
	}, [state.selectedValueChain])

	return (
		<>
			<section className="homepage__article" >
				<HeaderSubtitle heading="LNG Value Chain Margin (Pre-tax)" subtitle={showBy === "absolute" ? Properties.getDictionaryText(dictionaryList, `LNG_page_subheader_absolute`, "en") : Properties.getDictionaryText(dictionaryList, `LNG_page_subheader`, "en")} className="overview-header-with-subtitles" />
				<StickyElement to="top" floatBy={ESTIMATED_APP_HEADER_HEIGHT} className="lng-filters" stickyClassName="sticky">
					<LNGFilters
						onEvent={handleFilterChange}
						comparative={comparative}
						period={period}
						showBy={showBy}
						currency={currency}
						periodType={typeList}
						month={month}
						quarter={quarter}
						year={year}
						yearList={state.yearList}
						valueChain={valueChain}
						profitability={profitability}
						hideMonth={period !== "monthly" && period !== "ytd"}
						hideQuarter={period !== "quarterly"}
						stickyClassName="lng-filters" //This stickyClassName is meant for the JS for scrolling
					/>
				</StickyElement>

				<div className="homepage__article__segment">
					<div className="homepage__article__segment-tabs">
						<Skeleton loading={state.loading.summary}>
							<LNGSegments
								segments={segments}
								comparative={comparative}
								month={month}
								quarter={quarter}
								year={year}
								periodType={period}
								showBy={showBy}
								currencySymbol={currency.currencySymbol}
								selectedValueChain={state.selectedValueChain}
								onTabSwitch={handleTabChange}
								product={state.product}
							/>
						</Skeleton>

					</div>
					<Skeleton loading={state.loading.details}>
						<div className="homepage__article__tab-content">
							<div className="homepage__article__tab-content__contributors">
								<HeaderInfoPopup type="subheader"
									info={Properties.getDictionaryText(dictionaryList, `LNG_contributer_subheader_${state.selectedValueChain}`, "en")}
								>
									<h2>Top Margin Variance Contributors</h2>
								</HeaderInfoPopup>
								<div className="homepage__article__tab-content__container">
									<LNGContributors
										contributions={contributions}
										comparative={comparative}
										year={year}
										showBy={showBy}
										currencySymbol={currency.currencySymbol}
										selectedValueChain={state.selectedValueChain}
										month={month}
										quarter={quarter}
										periodType={period}
										lng={lng}
									></LNGContributors>
								</div>
							</div>
							{subSegments && subSegments.length > 0 && <LNGSubSegments
								subSegments={subSegments}
								comparative={comparative}
								month={month}
								quarter={quarter}
								periodType={period}
								year={year}
								currencySymbol={currency.currencySymbol}
								onRowClicked={onRowClicked}
								subHeader={`LNG_${state.selectedValueChain}_sub_segment`}
								selectedValueChain={state.selectedValueChain}
							></LNGSubSegments>}
						</div>
					</Skeleton>
				</div>
				<LNGPriceBenchmark
					benchmark={priceBenchmark}
					showBy={showBy}
					currencySymbol={currency.currencySymbol}
				></LNGPriceBenchmark>
			</section>

		</>
	)
}

/**
 * This codebase is using 'Undux' for global state management.
 * @see https://undux.org/ for more information
 *
 * Also @see https://undux.org/#examples/derived-state for more information on why 'withStore' was used.
 **/
export default AppStore.withStores(LNGStore.withStores(Overview));